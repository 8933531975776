@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .masthead {
    @apply text-48 font-bold tracking-tight;
  }
  .gradient-border {
    @apply relative;
  }
  .gradient-border::before {
    @apply absolute bottom-0 left-0 right-0 top-0 rounded-8 border-2 border-solid border-transparent bg-gradient-to-r bg-size-200 bg-pos-100 bg-origin-border transition-all duration-500 ease-in-out content-[''];
  }
  .gradient-border:hover::before {
    @apply bg-pos-0;
  }
  .heading {
    @apply text-38 font-medium;
  }
  .subheading {
    @apply text-24 font-bold;
  }
  .subtext {
    @apply text-16 font-medium text-gray-500;
  }
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #fff;
  color: #fff;
}

.gradient-border::before {
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.custom-dash {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23FE7ED9FF' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='14' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 12px;
}

/* set style for all scrollbars */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar-thumb:horizontal{
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.circle-1 {
 top: -5%;
 left: -2%;
 position: fixed;
 border-radius: 100%;
 width: min(40vw, 400px);
  height: min(40vw, 400px);
  background: radial-gradient(#452844, #45284400);
  z-index: 1;
}

.circle-2 {
  bottom: -10%;
  right: 10%;
  position: fixed;
  width: min(40vw, 400px);
  height: min(40vw, 400px);
  border-radius: 100%;
  background: radial-gradient(#283045, #28304500);
  z-index: 1;
 }

.filter-pink {
  filter: invert(71%) sepia(69%) saturate(1713%) hue-rotate(283deg) brightness(102%) contrast(99%);
}

textarea:focus, input:focus{
  outline: none !important;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  /* background: linear-gradient(180deg, #050409, #20273842); */
  background-color: #050409;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Roobert', sans-serif;
}

/* media query for mobile devices */ 
@media screen and (max-width: 900px) {
  .circle-1 {
    top: -5%;
    left: -30%;
    opacity: .8;
    width: 100vw;
    height: 100vw;
  }

  .circle-2 {
    bottom: -10%;
    right: -30%;
    width: 100vw;
    height: 100vw;
    opacity: .7;
  }
}

@media screen and (min-width: 2500px) {

  .circle-2 {
    right: 30%;
    opacity: 1;
  }
}

.texture {
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  background: rgb(0, 0, 0);
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 0.3) 100%
  );
}

.texture::before {
  content: "";
  background-image: url("/texture.jpeg");
  background-repeat: repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.05;
}

@media screen and (max-width: 900px) {
  .texture {
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Autocomplete styling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #232323 inset !important;
  background-color: #232323 !important;
  background-clip: content-box !important;
  -webkit-text-fill-color: white;
  caret-color: white;
}

textarea:focus,
input:focus {
  outline: none;
}

input {
  border-radius: 0;
}

input[type="search"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}

/* Circular progress bar */
/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

 .CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}
